<template>
  <div id="order-view-additional-payment-terms" class="mt-2">
    <b-overlay :show="quoteLoading">
      <b-card>
        <b-overlay :show="QuotePaymentTermLoading">
          <b-form-group
            :label="$tc('payment.term.title', 2)"
            label-for="quotation-payment-term"
          >
            <v-select
              v-if="quoteSelected && QuotePaymentTerm"
              id="quotation-payment-term"
              v-model="paymentTerm"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paymentTermOptions()"
              :reduce="item => item.value"
              :clearable="false"
              :searchable="false"
              :required="quotePaymentTermsRequired"
              @option:selected="updateQuotePaymentTermsRequired"
            >
              <template slot="selected-option" slot-scope="slotData">
                <slot name="selected-option" v-bind="{ ...slotData }">
                  {{ slotData.value|enumTranslate('quote_payment_term') }}
                </slot>
              </template>

              <template slot="option" slot-scope="slotData">
                <slot name="option" v-bind="{ ...slotData }">
                  {{ slotData.value|enumTranslate('quote_payment_term') }}
                </slot>
              </template>
            </v-select>
          </b-form-group>
        </b-overlay>
        <!--
        &lt;!&ndash; paymentTerm &ndash;&gt;
        <enum-select
          id="quotation-payment-term"
          v-model="paymentTerm"
          :label="$tc('payment.term.title', 2)"
          enum-class="QuotePaymentTerm"
          :clearable="false"
          :searchable="false"
          :required="quotePaymentTermsRequired"
          @option:selected="updateQuotePaymentTermsRequired"
        />-->

        <div v-if="paymentTerm !== 'on_contract'">
          <!-- Payment Specific -->
          <div class="d-flex justify-content-start align-items-start">
            <b-form-checkbox id="payment-specific-term" v-model="showConcretePaymentDate" @input="updateConcretePaymentDate" />
            <label for="payment-specific-term">{{ $t('payment.term.specific_terms') }}</label>
          </div>

          <!-- Concrete payment date-->

          <validation-provider v-if="showConcretePaymentDate" #default="{ errors }" :name="$t('payment.date.concrete_payment_date')" rules="">
            <b-form-group :label="$t('payment.date.concrete_payment_date')" label-for="quotation-conrete-payment-date" class="mt-1">
              <b-form-datepicker
                id="quotation-conrete-payment-date"
                v-model="concretePaymentDate"
                :placeholder="$t('payment.date.concrete_payment_date')"
                :locale="$i18n.locale"
                :state="errors[0] ? false : null"
                class="date-picker-tsp"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- note -->
          <div class="d-flex justify-content-between align-items-center my-1">
            <span>{{ $t('common.note') }} {{ $t('order.payment_term.note') }}</span>
          </div>

          <!-- note_condition -->
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ $t('order.payment_term.note_condition') }}</span>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'OrderViewAdditionalPaymentTerms',

  data() {
    return {
      showConcretePaymentDate: false,
    }
  },

  computed: {
    ...mapState('quote', ['quoteLoading']),
    ...mapState('enum', ['QuotePaymentTerm', 'QuotePaymentTermLoading']),
    ...mapFields('quote', ['quoteSelected', 'quoteSelected.quote.paymentTerm', 'quoteSelected.quote.concretePaymentDate', 'quotePaymentTermsRequired']),
  },

  mounted() {
    this.fetchEnum2('QuotePaymentTerm')
    if (this.concretePaymentDate !== null) this.showConcretePaymentDate = true
  },

  methods: {
    ...mapActions('enum', ['fetchEnum2']),

    updateConcretePaymentDate(value) {
      if (!value) {
        this.concretePaymentDate = null
        this.showConcretePaymentDate = false
      }
    },
    updateQuotePaymentTermsRequired(option) {
      option && (this.quotePaymentTermsRequired = false)
    },
    paymentTermOptions() {
      return this.QuotePaymentTerm.filter(paymentTerm => {
        if (paymentTerm.value === 'on_contract') {
          let onContractNeeded = false

          if (this.$can('QUOTE_ON_CONTRACT_EDIT')) {
            onContractNeeded = true
          }

          return onContractNeeded
        }

        return true
      })
    },
  },
}
</script>
